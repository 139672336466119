import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import MediaBlockImageLeft from '~/components/MediaBlockImageLeft'
import MediaBlockImageRight from '~/components/MediaBlockImageRight'
import ContenfulImageOverlayText from '~/components/ContenfulImageOverlayText'

const AboutUs = ({ data }) => {
  const { blocks } = { ...data.contentfulNewPage }

  const VideoBlock = data => {
    const { youtubeId } = { ...data.data }

    return (
      <div className="container-fluid w-100 px-0">
        <iframe
          className="w-100"
          style={{ height: 'calc(100vw/1.77)' }}
          title="youtube video"
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=0&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&start=0&enablejsapi=1&&widgetid=3`}
        />
      </div>
    )
  }

  return (
    <Layout>
      <Metadata title="About Us" />
      {blocks.map((block, index) => {
        if (block.__typename === 'ContentfulMediaBlock') {
          if (!block.imagePosition) {
            //Image on left
            return <MediaBlockImageLeft data={block} key={index + block.__typename} />
          }
          if (block.imagePosition) {
            //Image on Right
            return <MediaBlockImageRight data={block} key={index + block.__typename} />
          }
        }
        if (block.__typename === 'ContentfulImageWithOverlayText') {
          return <ContenfulImageOverlayText key={index} order={index} data={block} />
        }
        if (block.__typename === 'ContentfulVideoBlock') return <VideoBlock key={index} data={block} />
      })}
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  query NewPageAboutQueryTest {
    contentfulNewPage(identifier: { eq: "about-us" }) {
      title
      blocks {
        ... on ContentfulImageWithOverlayText {
          __typename
          id
          fontColor
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          subheading
          buttonTitle
          buttonLink
        }
        ... on ContentfulVideoBlock {
          __typename
          title
          youtubeId
        }
        ... on ContentfulMediaBlock {
          __typename
          id
          title
          showTitle
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          imageAltText
          imagePosition
          showBackground
          backgroundColor
          callToAction {
            title
            slug
          }
          callToActionRight
          content {
            raw
          }
        }
      }
    }
  }
`
